import { VueReCaptcha } from "vue-recaptcha-v3";
import type { IReCaptchaOptions } from "vue-recaptcha-v3/dist/IReCaptchaOptions";

export default defineNuxtPlugin((nuxtApp) => {
  const opt: IReCaptchaOptions = {
    siteKey: "6Lc77qMpAAAAAH55kdhMFLCZSfS9eWfsv9yeO2HQ",
    loaderOptions: {
      explicitRenderParameters: {
        badge: "bottomleft",
      },
    },
  };

  nuxtApp.vueApp.use(VueReCaptcha, opt);
});
