<template>
  <div
    class="w-full flex flex-1 flex-col inset-0 absolute bg-gradient-to-br from-[#513E99] to-[#202568]"
  >
    <div class="relative flex flex-col flex-1 mt-18 -mb-2">
      <NuxtImg
        src="/images/404_desktop.png"
        class="absolute inset-0 w-full h-full object-cover hidden md:block"
        fit="cover"
        format="webp"
      ></NuxtImg>
      <NuxtImg
        src="/images/404_mobile.png"
        class="absolute inset-0 w-full h-full object-cover block md:hidden"
        format="webp"
      ></NuxtImg>
      <div
        class="absolute inset-0 flex flex-col md:justify-center container mx-auto text-white gap-4 md:mt-20 mt-48"
      >
        <h2 class="inline">
          <span class="text-header-xl text-bold">
            <Icon
              icon="eos-icons:hourglass"
              class="text-3xl inline"
              inline
            ></Icon>

            We are working on this
          </span>
        </h2>
        <p class="text-p-sm">
          In the meantime, you can simply contact us or check other pages!
        </p>

        <div class="flex gap-4 uppercase">
          <Button @click="openModal">Contact Us</Button>
          <NuxtLink to="/">
            <Button
              class="bg-transparent hover:bg-black/10 text-white outline-1 outline"
              >Go to Home</Button
            >
          </NuxtLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import ContactModal from "~/components/global/ContactModal.vue";
import { Icon } from "@iconify/vue/dist/iconify.js";

const { $viewport } = useNuxtApp();

import { useModal } from "vue-final-modal";
const openModal = async () => {
  const modal = useModal({
    component: ContactModal,
  });

  await modal.open();
};
</script>

<style scoped></style>
