import type { RouterConfig } from "@nuxt/schema";

export default <RouterConfig>{
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
        top: 200,
      };
    }

    if (window.scrollY > 50) {
      window.scrollTo({ top: 0 });
    }
    return { x: 0, y: 0 };
  },
};
