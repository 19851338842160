import { default as contactcXfPQmVitgMeta } from "/Users/abdalrahmansaadulla/Desktop/website/pages/contact.vue?macro=true";
import { default as indexhHx6f6AzlwMeta } from "/Users/abdalrahmansaadulla/Desktop/website/pages/index.vue?macro=true";
import { default as portfolioFEzqeYttVKMeta } from "/Users/abdalrahmansaadulla/Desktop/website/pages/portfolio.vue?macro=true";
import { default as _91id_93B8Jdt79DA5Meta } from "/Users/abdalrahmansaadulla/Desktop/website/pages/services/[id].vue?macro=true";
export default [
  {
    name: "contact",
    path: "/contact",
    component: () => import("/Users/abdalrahmansaadulla/Desktop/website/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/Users/abdalrahmansaadulla/Desktop/website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "portfolio",
    path: "/portfolio",
    component: () => import("/Users/abdalrahmansaadulla/Desktop/website/pages/portfolio.vue").then(m => m.default || m)
  },
  {
    name: "services-id",
    path: "/services/:id()",
    component: () => import("/Users/abdalrahmansaadulla/Desktop/website/pages/services/[id].vue").then(m => m.default || m)
  }
]