import { basicContactForm } from "./forms";
import header from "./data/header.json";
import meta from "./data/meta.json";
import landing from "./data/landing.json";
import footer from "./data/footer.json";

export const menu = header.menu;
export const logo = header.logo;
export const contact = meta.contact;
export const socials = meta.socials;

export const heroActions = landing.hero.actions;

export const heroSection = landing.hero.section;

export const introSection = landing.intro;
export const productSection = landing.products;

export const businessSection = landing.businesses;

export const calltoActionSection = landing.callToAction;
export const gallerySection = landing.gallery;

export const testimonialSection = landing.testimonials;

export const buildWithUsSection = {
  ...landing.buildWithUs,
  form: basicContactForm,
  formSubmitEndpoint: "/api/contact",
};

export const footerSection = {
  copyrightText: footer.copyright,
  links: footer.links,
  socials: {
    title: footer.socialsTitle,
    items: socials,
  },
};

export const contactModal = {
  title: "Contact Us Now!",
  form: basicContactForm,
  formSubmitText: "Send",
  formSubmitEndpoint: "/api/contact",
  sidebar: {
    text: "Get in touch with Creative Advanced Technologies for a complimentary consultation and entrust your IT project to a team of seasoned software experts.",
    contactsTitle: "Contact us",
    contacts: contact,
  },
};
