export const basicContactForm = {
  first_name: {
    type: "text",
    rules: ["required"],
    autocomplete: "off",
    label: "First name*",
    placeholder: "Your name",
    floating: false,
    columns: {
      default: {
        container: 12,
      },
      md: {
        container: 6,
      },
    },
  },
  last_name: {
    type: "text",
    rules: ["required"],
    placeholder: "Your last name",
    autocomplete: "off",
    label: "Last name*",
    floating: false,
    columns: {
      default: {
        container: 12,
      },
      md: {
        container: 6,
      },
    },
  },
  email: {
    type: "text",
    label: "Email*",
    inputType: "email",
    rules: ["required", "email"],
    placeholder: "Your email",
    floating: false,
    columns: {
      default: {
        container: 12,
      },
      md: {
        container: 6,
      },
    },
  },
  phone: {
    type: "text",
    inputType: "tel",
    rules: ["required"],
    placeholder: "Your phone with country code",
    floating: false,
    columns: {
      default: {
        container: 12,
      },
      md: {
        container: 6,
      },
    },
    label: "Phone*",
  },
  country: {
    type: "select",
    native: false,

    label: "Country*",
    search: true,
    inputType: "search",
    rules: ["required"],
    autocomplete: "disabled",
    placeholder: "Your country",
    items: "/json/countries.json",
    labelProp: "name",
    valueProp: "name",

    displayMessages: false,
  },
  inqury_type: {
    type: "select",
    native: false,

    label: "Inquiry type*",
    inputType: "search",
    rules: ["required"],
    autocomplete: "disabled",
    placeholder: "Represents*",
    items: ["Business", "Individual"],
    columns: {
      default: {
        container: 12,
      },
      md: {
        container: 6,
      },
    },
  },
  company_name: {
    type: "text",

    autocomplete: "off",
    label: "Company name*",
    placeholder: "Your company name",
    floating: false,
    columns: {
      default: {
        container: 12,
      },
      md: {
        container: 6,
      },
    },
    rules: [{ required: ["inqury_type", "==", "Business"] }],
    conditions: [["inqury_type", "==", "Business"]],
  },
  company_website: {
    type: "text",
    rules: ["nullable"],
    ariaLabel: "website",
    autocomplete: "off",
    label: "Company website",
    placeholder: "Your company website",
    floating: false,
    columns: {
      default: {
        container: 12,
      },
      md: {
        container: 6,
      },
    },
    conditions: [["inqury_type", "==", "Business"]],
  },
  role: {
    type: "text",
    rules: ["nullable"],
    autocomplete: "off",
    label: "Role",
    placeholder: "Your role in the company",
    floating: false,
    columns: {
      default: {
        container: 12,
      },
      md: {
        container: 6,
      },
    },
    conditions: [["inqury_type", "==", "Business"]],
  },
  divider1: {
    type: "static",
    tag: "hr",
  },
  interest_type: {
    type: "select",
    native: false,

    label: "Interest*",
    inputType: "search",
    rules: ["required"],
    autocomplete: "disabled",
    placeholder: "Your interest*",
    items: ["Products", "Services", "Other"],
    columns: {
      default: {
        container: 12,
      },
      md: {
        container: 6,
      },
    },
  },
  services: {
    type: "select",
    native: false,
    label: "Services",
    inputType: "search",
    autocomplete: "disabled",
    placeholder: "Select service",
    items: [
      "E-Commerce and Logistics",
      "Custom Solution",
      "Saas",
      "IoT",
      "AI and Machine Learning",
      "Operational Support",
      "Backend Development",
    ],
    labelProp: "name",
    valueProp: "name",
    displayMessages: false,
    rules: [{ required: ["interest_type", "==", "Services"] }],
    conditions: [["interest_type", "==", "Services"]],
    columns: {
      default: {
        container: 12,
      },
      md: {
        container: 6,
      },
    },
  },
  products: {
    type: "select",
    native: false,
    label: "Products",
    inputType: "search",
    autocomplete: "disabled",
    placeholder: "Select product",
    items: [
      "E-Commerce and Logistics",
      "E-Learning",
      "Enrollment and Exam",
      "Management",
      "AI and Machine Learning",
      "Communication",
    ],
    labelProp: "name",
    valueProp: "name",
    displayMessages: false,
    conditions: [["interest_type", "==", "Products"]],
    rules: [{ required: ["interest_type", "==", "Products"] }],
    columns: {
      default: {
        container: 12,
      },
      md: {
        container: 6,
      },
    },
  },
  other: {
    type: "text",
    autocomplete: "off",
    label: "Other*",
    placeholder: "What are you interested in?",
    floating: false,
    columns: {
      default: {
        container: 12,
      },
      md: {
        container: 6,
      },
    },
    conditions: [["interest_type", "==", "Other"]],
    rules: [{ required: ["interest_type", "==", "Other"] }],
  },

  message: {
    type: "textarea",
    rules: ["nullable"],
    floating: false,
    columns: {
      container: 12,
    },
    label: "Tell us your needs",
    placeholder: "Tell us about your fantastic project",
  },
};
