import { defineNuxtPlugin } from '#app/nuxt'
import { LazyFancyBadge, LazyBuildSomethingSection, LazyBusinessSection, LazyFakeTimeline, LazyCallToAction, LazyScrollCarousel, LazyContactForm, LazyHeroActionItem, LazyHeroActions, LazyHeroSection, LazyIntroSection, LazyOverlayMenu, LazyOverlayMenuItem, LazyNotFound, LazyPageFooter, LazyPageHeader, LazyProductSummaryCard, LazyProjectGallerySection, LazyProductsSection, LazyRecomendationSection, LazyServiceHero, LazyStarReview, LazyBulletsCard, LazyBulletsCardList, LazyContactModal, LazyImageCard } from '#components'
const lazyGlobalComponents = [
  ["FancyBadge", LazyFancyBadge],
["BuildSomethingSection", LazyBuildSomethingSection],
["BusinessSection", LazyBusinessSection],
["FakeTimeline", LazyFakeTimeline],
["CallToAction", LazyCallToAction],
["ScrollCarousel", LazyScrollCarousel],
["ContactForm", LazyContactForm],
["HeroActionItem", LazyHeroActionItem],
["HeroActions", LazyHeroActions],
["HeroSection", LazyHeroSection],
["IntroSection", LazyIntroSection],
["OverlayMenu", LazyOverlayMenu],
["OverlayMenuItem", LazyOverlayMenuItem],
["NotFound", LazyNotFound],
["PageFooter", LazyPageFooter],
["PageHeader", LazyPageHeader],
["ProductSummaryCard", LazyProductSummaryCard],
["ProjectGallerySection", LazyProjectGallerySection],
["ProductsSection", LazyProductsSection],
["RecomendationSection", LazyRecomendationSection],
["ServiceHero", LazyServiceHero],
["StarReview", LazyStarReview],
["BulletsCard", LazyBulletsCard],
["BulletsCardList", LazyBulletsCardList],
["ContactModal", LazyContactModal],
["ImageCard", LazyImageCard],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
